// ANGULAR MODULES
import {Injectable} from '@angular/core';

// TYPE DEFINITIONS
import {FormlyFieldConfig, FormlyFormOptions} from '@ngx-formly/core';
import {ColumnsTPL, FormTPL, ListTPL, ListTPLOptions} from './app.config.interfaces';

import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import emailMask from 'text-mask-addons/dist/emailMask';

import set from 'lodash-es/set';
import get from 'lodash-es/get';


import { environment } from 'environments/environment';


@Injectable()
export class AppConfig {

    public appName = 'OPDM - openpolis data manager';

    /* --------------------------------------------------------------------------------------------------------------------
     * DEBUG OPTIONS
     * -------------------------------------------------------------------------------------------------------------------- */
    public debug = !environment.production;

    /* --------------------------------------------------------------------------------------------------------------------
     * API OPTIONS
     * -------------------------------------------------------------------------------------------------------------------- */
    public API = environment.API;

    /* --------------------------------------------------------------------------------------------------------------------
     * VENDOR MODULES OPTIONS
     * -------------------------------------------------------------------------------------------------------------------- */

    // angular2-text-mask
    // public dateMask = {mask: [/\d/, /\d/, /\d/, /\d/, '-', /[0-1]/, /\d/, '-', /[0-3]/, /\d/], guide: false};
    // public emailMask = {mask: emailMask};
    public numberMask = {mask: createNumberMask({prefix: '', thousandsSeparatorSymbol: '.'}), guide: false};

    // ngx-bootstrap/datepicker
    public datePickerConfig = {
        containerClass: 'theme-blue',
        dateInputFormat: 'YYYY-MM-DD',
        showWeekNumbers: false
    };

    /* --------------------------------------------------------------------------------------------------------------------
     * LIST OPTIONS
     * -------------------------------------------------------------------------------------------------------------------- */
    public pageSizeList = [10, 15, 20, 25, 50, 100, 200];
    public pageSizeSelect = [
        {label: '10', value: '10'},
        {label: '15', value: '15'},
        {label: '20', value: '20'},
        {label: '25', value: '25'},
        {label: '50', value: '50'},
        {label: '100', value: '100'},
        {label: '200', value: '200'}
    ];
    public pageSizeDefault = '15';

    public othernameTypes = [
        {label: 'Precedente'     , value: 'FOR'},
        {label: 'Alternativo'    , value: 'ALT'},
        {label: 'Conosciuto come', value: 'AKA'},
        {label: 'Soprannome'     , value: 'NIC'},
        {label: 'Acronimo'       , value: 'ACR'}
    ];
    public othernameTypesDefault = null;


    /* --------------------------------------------------------------------------------------------------------------------
     *
     * FORM SUB MODULES
     *
     * -------------------------------------------------------------------------------------------------------------------- */

    // IMPORTANT NOTE:
    // some API data sources defined in "codelistPath" need pre-processing to be converted to a suitable model
    // have a look to the "processData" method in the "api-common.service.ts" file

    /* tslint:disable */
    // @formatter:off
    // ^^^^^^^^^^^^^^ see https://stackoverflow.com/a/19492318 for how to activate formatter disabling feature on IntelliJ based IDE
    private SUB_CONTACT_DETAILS(): FormlyFieldConfig {
        return {
            wrappers: ['panel'],
            // expressionProperties: { 'templateOptions.hidden': '!formState.model.id' },
            hideExpression: '!formState.model.id',
            key: 'contact_details',
            type: 'datatable',
            templateOptions: {
                label: 'Contatti',
                columns: [
                    <ColumnsTPL>{name:'Espandi'      , prop:'id'          , head:'hdr_exp', cell:'exp' , resize:0, sort:0, drag:0, flexGrow:0, width:30, canAutoResize:0},
                    <ColumnsTPL>{name:'Tipo contatto', prop:'contact_type', head:'hdr', cell:'text'    , resize:0, sort:0, drag:0, flexGrow:1, width: 120, canAutoResize:0},
                    <ColumnsTPL>{name:'Contatto'     , prop:'value'       , head:'hdr', cell:'text'    , resize:0, sort:0, drag:1, flexGrow:2                            },
                    <ColumnsTPL>{name:'Data inizio'  , prop:'start_date'  , head:'hdr', cell:'ddMMyyyy', resize:0, sort:0, drag:1, flexGrow:1                            },
                    <ColumnsTPL>{name:'Data fine'    , prop:'end_date'    , head:'hdr', cell:'ddMMyyyy', resize:1, sort:1, drag:1, flexGrow:1,                           },
                    <ColumnsTPL>{name:'Motivo fine'  , prop:'end_reason'  , head:'hdr', cell:'text'    , resize:1, sort:1, drag:1, flexGrow:2,                           },
                    <ColumnsTPL>{name:'Sorgente'     , prop:'source'      , head:'hdr', cell:'text'    , resize:1, sort:1, drag:1, flexGrow:2,                           },
                    <ColumnsTPL>{name:'Colonne'      , prop:''            , head:'hdr_sel', cell:'delete', resize:0, sort:0, drag:0, flexGrow:1, width: 60, canAutoResize:0},
                ],
                row_detail: {
                    title: 'modifica contatto',
                    type: 'form',
                    allowAddRow: true
                }
            },
            fieldArray: {
                fieldGroupClassName: 'row',
                fieldGroup: [
                    {key: 'contact_type', type: 'select2', className: 'col-4', templateOptions: {label: 'Tipo contatto', required: true, codelistPath: 'contact_types_choices', valueProp: 'type'}},
                    {key: 'value'       , type: 'input'  , className: 'col-4', templateOptions: {label: 'Contatto'     , required: true}},
                    {key: 'start_date'  , type: 'date'   , className: 'col-4', templateOptions: {label: 'Data inizio'  , width: '130px'}},
                    {key: 'end_date'    , type: 'date'   , className: 'col-4', templateOptions: {label: 'Data fine'    , width: '130px'}},
                    {key: 'end_reason'  , type: 'input'  , className: 'col-4', templateOptions: {label: 'Motivo fine'                  }},
                    {key: 'note'        , type: 'input'  , className: 'col-4', templateOptions: {label: 'Nota'                         }},
                ]
            }
        }
    };

    private SUBFORM_ELECTORAL_RESULT_SINGLE_LIST_RESULTS(): FormlyFieldConfig {
      return {
        wrappers: ['panel'],
        key: '',
        type: 'datatable',
        templateOptions: {
          label: 'Contatti',
          columns: [
            <ColumnsTPL>{name:'Espandi'        , prop:'id'              , head:'hdr_exp', cell:'exp' , resize:0, sort:0, drag:0, flexGrow:0, width:30, canAutoResize:0},
            <ColumnsTPL>{name:'Nome'           , prop:'name'            , head:'hdr', cell:'text'    , resize:0, sort:0, drag:0, flexGrow:1, width: 120, canAutoResize:0},
            <ColumnsTPL>{name:'Colore politico', prop:'political_colour', head:'hdr', cell:'text'    , resize:0, sort:0, drag:1, flexGrow:2                            },
            <ColumnsTPL>{name:'Partiti'        , prop:'parties'         , head:'hdr', cell:'text', resize:0, sort:0, drag:1, flexGrow:1                            },
          ],
          row_detail: {
            title: 'modifica risultato',
            type: 'form'
          }
        },
        fieldArray: {
          fieldGroupClassName: 'row',
          fieldGroup: [
            {key: 'name'            , type: 'input', className: 'col-4', templateOptions: {label: 'Nome', required: true, codelistPath: 'contact_types_choices', valueProp: 'type'}},
            {key: 'political_colour', type: 'input'  , className: 'col-4', templateOptions: {label: 'Colore politico'     , required: true}},
            {key: 'parties'         , type: 'input'   , className: 'col-4', templateOptions: {label: 'Partiti'  , width: '130px'}},
          ]
        }
      }
    }

    private SUBFORM_PERSON_SINGLE_MEMBERSHIP(): FormlyFieldConfig {
        return {
            fieldGroupClassName: 'row',
            fieldGroup: [
                {key:'organization'   , type:'select2', className:'col-10', templateOptions: {label:'Organizzazione', required: true,
                        codelistPath: 'organizations', codeListParams: {page_size: 200}, codeListPaged: true} },
                {key:'post_role'      , type:'select2', className:'col-6', templateOptions: {label:'Ruolo', required: true,
                        filterField: 'organization', codelistPath: 'persons/role_types', codeListParams: {organization: '', page_size: 15}, codeListPaged: true, labelProp: 'label', valueProp: 'label'}
                },
                {key:'person'         , type:'select2', className:'col-8', templateOptions: {label:'Persona', required: true,
                                                        codelistPath: 'persons', codeListPaged: true, labelProp: 'total_label', defaultValue: '${formState.model.id}'} },
                {key:'label'          , type:'input'  , className:'col-5', templateOptions: {label:'Descrizione'    },
                    hooks: {
                        onInit: function(field: any) {
                          const { form, model, options } = field;
                          field.app_subscription_change = form.get('post_role').valueChanges.subscribe(val => {
                            field.formControl.setValue(val);
                          });
                        },
                      onDestroy: function(field: any) {
                        field.app_subscription_change.unsubscribe();
                      }
                    }
                },
                {key: 'area'          , type:'select2',className:'col-5', templateOptions: {label: 'Area', codelistPath: 'areas', codeListPaged: true}},
                {key: 'start_date'    , type:'date'  , className:'col-4' , templateOptions: {label: 'Data inizio', required: true  }},
                {key: 'end_date'      , type:'date'  , className:'col-4' , templateOptions: {label: 'Data fine'                    }},
                {key: 'end_reason'    , type:'input' , className:'col-6' , templateOptions: {label: 'Motivo fine'                  }},
                {key: 'electoral_list_descr_tmp', type:'input'  , className:'col-10', templateOptions: {label: 'Lista di elezione'}},
                {key: 'constituency_descr_tmp'  , type:'input'  , className:'col-6' , templateOptions: {label: 'Circoscrizione elettorale' }},
                {key: 'electoral_event'         , type:'select2', className:'col-8' , templateOptions: {label: 'Evento elettorale', required: false,
                    codelistPath: 'keyevents', codeListParams: {page_size: 50}, codeListPaged: true} },
                {key:'appointed_by'   , type:'select2', className:'col-16', templateOptions: {label:'Nominato da', required: false,
                        codelistPath: 'memberships', codeListParams: {page_size: 50}, codeListPaged: true, labelProp: 'appointer', valueProp: 'id'} },
            ]
        }
    }

    private SUBFORM_ORGANIZATION_SINGLE_MEMBERSHIP(): FormlyFieldConfig {
        return {
            fieldGroupClassName: 'row',
            fieldGroup: [
                {key:'organization'   , type:'select2', className:'col-10', templateOptions: {label:'Organizzazione', required: true,
                        codelistPath: 'organizations', codeListParams: {page_size: 200}, codeListPaged: true, defaultValue: '${formState.model.id}'} },
                {key:'post_role'      , type:'select2', className:'col-6', templateOptions: {label:'Ruolo', required: true,
                        filterField: 'organization', codelistPath: 'persons/role_types', codeListParams: {organization: '', page_size: 15}, codeListPaged: true, labelProp: 'label', valueProp: 'label'}
                },
                {key:'person'         , type:'select2', className:'col-8', templateOptions: {label:'Persona', required: true,
                                                        codelistPath: 'persons', codeListPaged: true, labelProp: 'total_label'} },
                {key:'label'          , type:'input'  , className:'col-10', templateOptions: {label:'Descrizione'    },
                    hooks: {
                        onInit: function(field) {
                            const { form, model, options } = field;
                            form.get('post_role').valueChanges.subscribe(val => {
                                field.formControl.setValue(val);
                            });
                        }
                    }
                },
                {key: 'start_date'    , type:'date'  , className:'col-4' , templateOptions: {label: 'Data inizio', required: true  }},
                {key: 'end_date'      , type:'date'  , className:'col-4' , templateOptions: {label: 'Data fine'                    }},
                {key: 'end_reason'    , type:'input'  , className:'col-6' , templateOptions: {label: 'Motivo fine'                  }},
                {key: 'electoral_list_descr_tmp', type:'input'  , className:'col-10', templateOptions: {label: 'Lista di elezione'}},
                {key: 'constituency_descr_tmp'  , type:'input'  , className:'col-6' , templateOptions: {label: 'Circoscrizione elettorale' }},
                {key: 'electoral_event'         , type:'select2', className:'col-8' , templateOptions: {label: 'Evento elettorale', required: false,
                    codelistPath: 'keyevents', codeListParams: {page_size: 50}, codeListPaged: true} },
            ]
        }
    }

    private SUB_APPOINTEES(): FormlyFieldConfig {
      return {
        wrappers: ['panel'],
        // expressionProperties: { 'templateOptions.hidden': '!formState.model.id' },
        hideExpression: '!formState.model.id',
        key: 'appointees', type: 'datatable',
        templateOptions: {
          label: 'Nominati',
          columns: [
            <ColumnsTPL>{name:'#'             , prop:''                   , head:'hdr'    , cell:'membership' , resize:0, sort:0, drag:0, flexGrow:0, width:100, canAutoResize:0},
            <ColumnsTPL>{name:'Persona'       , prop:'person_name'        , head:'hdr'    , cell:'text'       , resize:1, sort:1, drag:1, flexGrow:3,                           },
            <ColumnsTPL>{name:'Incarico'      , prop:'label'              , head:'hdr'    , cell:'text'       , resize:1, sort:1, drag:1, flexGrow:3,                           },
            <ColumnsTPL>{name:'Data inizio'   , prop:'start_date'         , head:'hdr'    , cell:'ddMMyyyy'   , resize:1, sort:1, drag:1, flexGrow:1,                           },
            <ColumnsTPL>{name:'Data fine'     , prop:'end_date'           , head:'hdr'    , cell:'ddMMyyyy'   , resize:1, sort:1, drag:1, flexGrow:1,                           },
          ]
        },
        // fieldArray: this.SUBFORM_PERSON_SINGLE_MEMBERSHIP()
      }
    }

    private SUB_CLASSIFICATIONS(apipath: string, id: number, alive: boolean): FormlyFieldConfig {
        return {
            wrappers: ['panel'],
            // expressionProperties: { 'templateOptions.hidden': '!formState.model.id' },
            hideExpression: '!formState.model.id',
            key: 'classifications', type: 'datatable',
            templateOptions: {
                label: 'Classificazioni',
                columns: [
                    <ColumnsTPL>{name:'Espandi'        , prop:'id'      , head:'hdr_exp' , cell:'exp'     , resize:0, sort:0, drag:0, flexGrow:0, width:30, canAutoResize:0},
                    <ColumnsTPL>{name:'Schema'         , prop:'scheme'  , head:'hdr'     , cell:'text'    , resize:0, sort:0, drag:0, flexGrow:1},
                    <ColumnsTPL>{name:'Classificazione', prop:'descr'   , head:'hdr'     , cell:'text'    , resize:0, sort:0, drag:1, flexGrow:2},
                    <ColumnsTPL>{name:'Colonne'        , prop:''        , head:'hdr_sel' , cell:'delete'  , resize:0, sort:0, drag:0, flexGrow:1, width: 60, canAutoResize:0},
/*                      <ColumnsTPL>{name:'Data inizio'    , prop:'start_date'          , head:'hdr', cell:'ddMMyyyy', resize:0, sort:0, drag:1, flexGrow:1},
                    <ColumnsTPL>{name:'Data fine'      , prop:'end_date'            , head:'hdr', cell:'ddMMyyyy', resize:1, sort:1, drag:1, flexGrow:1},
                    <ColumnsTPL>{name:'Motivo fine'    , prop:'end_reason'          , head:'hdr', cell:'text'    , resize:1, sort:1, drag:1, flexGrow:2},*/
                ],
                row_detail: {
                        title: 'modifica classificazione',
                        type: 'form',
                        allowAddRow: true
                }
            },
            fieldArray: {
                fieldGroupClassName: 'row',
                fieldGroup: [
                    {key: 'scheme'        , type: 'select2', className: 'col-8', templateOptions: {label: 'Schema', valueProp: 'scheme', labelProp: 'scheme', required: true, codelistPath: apipath + '/classification_types', onChange: true}},
                    {key: 'id'            , type: 'select2', className: 'col-16', templateOptions: {label: 'Descrizione', labelProp: 'descr', required: true, filterField: 'scheme', codelistPath: 'classifications', codeListParams: {scheme: ''}, codeListPaged: true}},
/*                    {key: 'start_date'    , type: 'date'   , className: 'col-4', templateOptions: {label: 'Data inizio', width: '130px'}},
                    {key: 'end_date'      , type: 'date'   , className: 'col-4', templateOptions: {label: 'Data fine'  , width: '130px'}},
                    {key: 'end_reason'    , type: 'input'  , className: 'col-4', templateOptions: {label: 'Motivo fine'   }},*/
                ]
            }
        }
    }

    private SUB_SUBORGS(apipath: string): FormlyFieldConfig {
        return {
            wrappers: ['panel'],
            hideExpression: '!formState.model.id',
            key: 'children', type: 'datatable',
            templateOptions: {
                label: 'Organizzazioni subordinate',
                columns: [
                    <ColumnsTPL>{name:'Organizzazione' , prop:''               , head:'hdr', cell:'organization', resize:0, sort:0, drag:0, flexGrow:1},
                    <ColumnsTPL>{name:'Forma giuridica', prop:'forma_giuridica', head:'hdr', cell:'text'        , resize:0, sort:0, drag:0, flexGrow:1},
                ]
            },
            fieldArray: {}
        }
    }

    private SUB_IDENTIFIERS(apipath: string): FormlyFieldConfig{
        return {
            wrappers: ['panel'],
            // expressionProperties: { 'templateOptions.hidden': '!formState.model.id' },
            hideExpression: '!formState.model.id',
            key: 'identifiers', type: 'datatable',
            templateOptions: {
                label: 'Identificatori',
                columns: [
                    <ColumnsTPL>{name:'Espandi'       , prop:'id'        , head:'hdr_exp', cell:'exp'     , resize:0, sort:0, drag:0, flexGrow:0, width:30, canAutoResize:0},
                    <ColumnsTPL>{name:'Schema'        , prop:'scheme'    , head:'hdr'    , cell:'text'    , resize:0, sort:0, drag:0, flexGrow:1},
                    <ColumnsTPL>{name:'Identificatore', prop:'identifier', head:'hdr'    , cell:'text'    , resize:0, sort:0, drag:1, flexGrow:2},
                    <ColumnsTPL>{name:'Data inizio'   , prop:'start_date', head:'hdr'    , cell:'ddMMyyyy', resize:0, sort:0, drag:1, flexGrow:1},
                    <ColumnsTPL>{name:'Data fine'     , prop:'end_date'  , head:'hdr'    , cell:'ddMMyyyy', resize:1, sort:1, drag:1, flexGrow:1},
                    <ColumnsTPL>{name:'Motivo fine'   , prop:'end_reason', head:'hdr'    , cell:'text'    , resize:1, sort:1, drag:1, flexGrow:2},
                    <ColumnsTPL>{name:'Sorgente'      , prop:'source'    , head:'hdr'    , cell:'text'    , resize:1, sort:1, drag:1, flexGrow:2},
                    <ColumnsTPL>{name:'Colonne'       , prop:''          , head:'hdr_sel', cell:'delete'  , resize:0, sort:0, drag:0, flexGrow:1, width: 60, canAutoResize:0},
                ],
                row_detail: {
                    title: 'modifica identificatore',
                    type: 'form',
                    allowAddRow: true
                }
            },
            fieldArray: {
                fieldGroupClassName: 'row',
                fieldGroup: [
                    {key: 'scheme'    , type: 'select2', className: 'col-4', templateOptions: {label: 'Schema'        , required: true, codelistPath: apipath + '/identifier_types'}},
                    {key: 'identifier', type: 'input'  , className: 'col-4', templateOptions: {label: 'Identificatore', required: true}},
                    {key: 'start_date', type: 'date'   , className: 'col-4', templateOptions: {label: 'Data inizio'  , width: '130px'}},
                    {key: 'end_date'  , type: 'date'   , className: 'col-4', templateOptions: {label: 'Data fine'    , width: '130px'}},
                    {key: 'end_reason', type: 'input'  , className: 'col-4', templateOptions: {label: 'Motivo fine'   }},
                    {key: 'source'    , type: 'input'  , className: 'col-4', templateOptions: {label: 'Sorgente'      }},
                ]
            }
        }
    };

    private SUB_OTHERNAMES(apipath: string): FormlyFieldConfig{
        return {
            wrappers: ['panel'],
            // expressionProperties: { 'templateOptions.hidden': '!formState.model.id' },
            hideExpression: '!formState.model.id',
            key: 'other_names', type: 'datatable',
            templateOptions: {
                label: 'Altri nomi',
                columns: [
                    <ColumnsTPL>{name:'Espandi'       , prop:''        , head:'hdr_exp', cell:'exp'     , resize:0, sort:0, drag:0, flexGrow:0, width:30, canAutoResize:0},
                    <ColumnsTPL>{name:'Tipo'          , prop:'othername_type', head:'hdr', cell:'text'    , resize:0, sort:0, drag:0, flexGrow:1},
                    <ColumnsTPL>{name:'Nome'          , prop:'name'      , head:'hdr'    , cell:'text'    , resize:0, sort:0, drag:1, flexGrow:2},
                    <ColumnsTPL>{name:'Data inizio'   , prop:'start_date', head:'hdr'    , cell:'ddMMyyyy', resize:0, sort:0, drag:1, flexGrow:1},
                    <ColumnsTPL>{name:'Data fine'     , prop:'end_date'  , head:'hdr'    , cell:'ddMMyyyy', resize:1, sort:1, drag:1, flexGrow:1},
                    <ColumnsTPL>{name:'Motivo fine'   , prop:'end_reason', head:'hdr'    , cell:'text'    , resize:1, sort:1, drag:1, flexGrow:2},
                    <ColumnsTPL>{name:'Sorgente'      , prop:'source'    , head:'hdr'    , cell:'text'    , resize:1, sort:1, drag:1, flexGrow:2},
                    <ColumnsTPL>{name:'Note'          , prop:'note'      , head:'hdr'    , cell:'text'    , resize:1, sort:1, drag:1, flexGrow:2},
                    <ColumnsTPL>{name:'Colonne'       , prop:''          , head:'hdr_sel', cell:'delete'  , resize:0, sort:0, drag:0, flexGrow:1, width: 60, canAutoResize:0},
                ],
                row_detail: {
                    title: 'modifica altro nome',
                    type: 'form',
                    allowAddRow: true
                }
            },
            fieldArray: {
                fieldGroupClassName: 'row',
                fieldGroup: [
                    {key: 'othername_type', type:'select'  , className: 'col-4', templateOptions: {label: 'Tipo', required: true, options: this.othernameTypes}, defaultValue: this.othernameTypesDefault },
                    {key: 'name'          , type: 'input'  , className: 'col-8', templateOptions: {label: 'Nome', required: true}},
                    {key: 'start_date'    , type: 'date'   , className: 'col-4', templateOptions: {label: 'Data inizio'  , width: '130px'}},
                    {key: 'end_date'      , type: 'date'   , className: 'col-4', templateOptions: {label: 'Data fine'    , width: '130px'}},
                    {key: 'end_reason'    , type: 'input'  , className: 'col-4', templateOptions: {label: 'Motivo fine'   }},
                    {key: 'source'        , type: 'input'  , className: 'col-12', templateOptions: {label: 'Sorgente'      }},
                    {key: 'note'          , type: 'input'  , className: 'col-12', templateOptions: {label: 'Nota'      }},
                ]
            }
        }
    };

    private SUB_KEY_EVENTS(apipath: string): FormlyFieldConfig {
        return {
            wrappers: ['panel'],
            // expressionProperties: { 'templateOptions.hidden': '!formState.model.id' },
            hideExpression: '!formState.model.id',
            key: 'key_events', type: 'datatable',
            templateOptions: {
                label: 'Eventi chiave',
                columns: [
                    <ColumnsTPL>{name:'Espandi'       , prop:'id'        , head:'hdr_exp', cell:'exp'     , resize:0, sort:0, drag:0, flexGrow:0, width:30, canAutoResize:0},
                    <ColumnsTPL>{name:'Nome'          , prop:'name'      , head:'hdr'    , cell:'text'    , resize:0, sort:0, drag:0, flexGrow:1},
/*                    <ColumnsTPL>{name:'Identificatore', prop:'key_event.identifier', head:'hdr'    , cell:'text'    , resize:0, sort:0, drag:1, flexGrow:2},
                    <ColumnsTPL>{name:'Tipo evento'   , prop:'key_event.event_type', head:'hdr'    , cell:'text'    , resize:0, sort:0, drag:1, flexGrow:2},
                    <ColumnsTPL>{name:'Data inizio'   , prop:'key_event.start_date', head:'hdr'    , cell:'ddMMyyyy', resize:0, sort:0, drag:1, flexGrow:1},
                    <ColumnsTPL>{name:'Data fine'     , prop:'key_event.end_date'  , head:'hdr'    , cell:'ddMMyyyy', resize:1, sort:1, drag:1, flexGrow:1},
                    <ColumnsTPL>{name:'URL'           , prop:'key_event.url'       , head:'hdr'    , cell:'text'    , resize:1, sort:1, drag:1, flexGrow:2},*/
                    <ColumnsTPL>{name:'Colonne'       , prop:''                    , head:'hdr_sel', cell:'delete'  , resize:0, sort:0, drag:0, flexGrow:1, width: 60, canAutoResize:0},
                ],
                row_detail: {
                    title: 'modifica evento chiave',
                    type: 'form',
                    allowAddRow: true
                }
            },
            fieldArray: {
                fieldGroupClassName: 'row',
                fieldGroup: [
                    {key: 'id', type: 'select2', className: 'col-24', templateOptions: {label: 'Evento'        , required: true, codelistPath: '/keyevents'}},
                ]
            }
        }
    };

    private SUB_LINKS(): FormlyFieldConfig {
        return {
            wrappers: ['panel'],
            // expressionProperties: { 'templateOptions.hidden': '!formState.model.id' },
            hideExpression: '!formState.model.id',
            key: 'links', type: 'datatable',
            templateOptions: {
                label: 'Collegamenti',
                columns: [
                    <ColumnsTPL>{name:'Espandi', prop:null     , head:'hdr_exp', cell:'exp'   , resize:0, sort:0, drag:0, flexGrow:0, width:30, canAutoResize:0},
                    /*<ColumnsTPL>{name:'ID'     , prop:'link_id', head:'hdr'    , cell:'text'  , resize:0, sort:0, drag:0, flexGrow:1},*/
                    <ColumnsTPL>{name:'URL'    , prop:'url'    , head:'hdr'    , cell:'text'  , resize:0, sort:0, drag:0, flexGrow:5},
                    <ColumnsTPL>{name:'Nota'   , prop:'note'   , head:'hdr'    , cell:'text'  , resize:0, sort:0, drag:0, flexGrow:5},
                    <ColumnsTPL>{name:'Colonne', prop:''       , head:'hdr_sel', cell:'delete', resize:0, sort:0, drag:0, flexGrow:1, width: 60, canAutoResize:0},
                ],
                row_detail: {
                    title: 'modifica collegamento',
                    type: 'form',
                    allowAddRow: true
                }
            },
            fieldArray: {
                fieldGroupClassName: 'row',
                fieldGroup: [
                    {key: 'url'    , type: 'url'  , className:'col-12', templateOptions: {label: 'URL' , required: true }},
                    {key: 'note'   , type: 'input', className:'col-12', templateOptions: {label: 'Nota', required: false}},
                ]
            }
        }
    }

    private SUB_SOURCES(): FormlyFieldConfig {
        return {
            wrappers: ['panel'],
            // expressionProperties: { 'templateOptions.hidden': '!formState.model.id' },
            hideExpression: '!formState.model.id',
            key: 'sources', type: 'datatable',
            templateOptions: {
                label: 'Sorgenti',
                columns: [
                    <ColumnsTPL>{name:'Espandi', prop:null       , head:'hdr_exp', cell:'exp' , resize:0, sort:0, drag:0, flexGrow:0, width:30, canAutoResize:0},
                    /*<ColumnsTPL>{name:'ID'     , prop:'source_id', head:'hdr'    , cell:'text', resize:0, sort:0, drag:0, flexGrow:1},*/
                    <ColumnsTPL>{name:'URL'    , prop:'url'      , head:'hdr'    , cell:'text', resize:0, sort:0, drag:0, flexGrow:5},
                    <ColumnsTPL>{name:'Nota'   , prop:'note'     , head:'hdr'    , cell:'text', resize:0, sort:0, drag:0, flexGrow:5},
                    <ColumnsTPL>{name:'Colonne', prop:''         , head:'hdr_sel', cell:'delete', resize:0, sort:0, drag:0, flexGrow:1, width: 60, canAutoResize:0},
                ],
                row_detail: {
                    title: 'modifica sorgente',
                    type: 'form',
                    allowAddRow: true
                }
            },
            fieldArray: {
                fieldGroupClassName: 'row',
                fieldGroup: [
                    {key: 'url'      , type: 'url'  , className:'col-12', templateOptions: {label: 'URL' , required: true}},
                    {key: 'note'     , type: 'input', className:'col-12', templateOptions: {label: 'Nota', required: true}}
                ]
            }
        }
    }

    private SUB_PERSON_MEMBERSHIPS(apipath: string, id: number): FormlyFieldConfig {
        return {
            wrappers: ['panel'],
            // expressionProperties: { 'templateOptions.hidden': '!formState.model.id' },
            hideExpression: '!formState.model.id',
            key: 'memberships', type: 'datatable',
            templateOptions: {
                label: 'Incarichi',
                datasource: id ? 'memberships?person__id=' + id + '&page_size=200&ordering=-end_date&active_status=' : null,
                columns: [
                    <ColumnsTPL>{name:'Espandi'       , prop:null          , head:'hdr_exp', cell:'exp'        , resize:0, sort:0, drag:0, flexGrow:0, width:30, canAutoResize:0},
                    <ColumnsTPL>{name:'#'             , prop:'id'          , head:'hdr'    , cell:'id'         , resize:0, sort:0, drag:0, flexGrow:0, width:100, canAutoResize:0},
                    <ColumnsTPL>{name:'Organizzazione', prop:'organization', head:'hdr'    , cell:'organization',resize:1, sort:1, drag:1, flexGrow:3,                          },
                    <ColumnsTPL>{name:'Persona'       , prop:'person'      , head:'hdr'    , cell:'memberperson',resize:1, sort:1, drag:1, flexGrow:3,                          },
                    <ColumnsTPL>{name:'Ruolo'         , prop:'post.role'   , head:'hdr'    , cell:'text'       , resize:1, sort:1, drag:1, flexGrow:3,                          },
                    <ColumnsTPL>{name:'Descrizione'   , prop:'label'       , head:'hdr'    , cell:'text'       , resize:1, sort:1, drag:1, flexGrow:3,                          },
                    <ColumnsTPL>{name:'Data inizio'   , prop:'start_date'  , head:'hdr'    , cell:'ddMMyyyy'   , resize:1, sort:1, drag:1, flexGrow:1,                          },
                    <ColumnsTPL>{name:'Data fine'     , prop:'end_date'    , head:'hdr'    , cell:'ddMMyyyy'   , resize:1, sort:1, drag:1, flexGrow:1,                          },
                    <ColumnsTPL>{name:'Colonne'       , prop:''            , head:'hdr_sel', cell:'delete'     , resize:0, sort:0, drag:0, flexGrow:0, width:60, canAutoResize:0},
                ],
                row_detail: {
                    title: 'modifica incarico',
                    type: 'form',
                    datasource: 'memberships',
                    datasourceParams: (model) => { return model.id; },
                    allowAddRow: true,
                    restoreDetailOpenStatus: false
                }
            },
            // fieldGroup: this.SUBFORM_PERSON_SINGLE_MEMBERSHIP().fieldGroup
            fieldArray: this.SUBFORM_PERSON_SINGLE_MEMBERSHIP()

        }
    }

    private SUB_PERSON_OWNERSHIPS(apipath: string, id: number): FormlyFieldConfig {
        return {
            wrappers: ['panel'],
            // expressionProperties: { 'templateOptions.hidden': '!formState.model.id' },
            hideExpression: '!formState.model.id',
            key: 'ownerships', type: 'datatable',
            templateOptions: {
                label: 'Partecipazioni societarie',
                columns: [
                    <ColumnsTPL>{name:'Organizzazione', prop:'owned_organization', head:'hdr'    , cell:'organization',resize:1, sort:1, drag:1, flexGrow:3,                          },
                    <ColumnsTPL>{name:'Quota detenuta %' ,prop:'percentage', head:'hdr', cell:'text', resize:1, sort:0, drag:1, flexGrow:1,                 },
                    <ColumnsTPL>{name:'Data inizio'   , prop:'start_date'  , head:'hdr'    , cell:'ddMMyyyy'   , resize:1, sort:1, drag:1, flexGrow:1,                          },
                    <ColumnsTPL>{name:'Data fine'     , prop:'end_date'    , head:'hdr'    , cell:'ddMMyyyy'   , resize:1, sort:1, drag:1, flexGrow:1,                          },
                    <ColumnsTPL>{name:'Colonne'       , prop:''            , head:'hdr_sel', cell:'empty'     , resize:0, sort:0, drag:0, flexGrow:0, width:60, canAutoResize:0},
                ]
            }
        }
    }

    private SUB_OWNERSHIPS_AS_OWNER(apipath: string, id: number): FormlyFieldConfig {
        return {
            wrappers: ['panel'],
            // expressionProperties: { 'templateOptions.hidden': '!formState.model.id' },
            hideExpression: '!formState.model.id',
            key: 'ownerships_as_owner', type: 'datatable',
            templateOptions: {
                label: 'Ha partecipazioni in',
                datasource: id ? apipath + '/' + id + '/ownerships_as_owner?active_status=current' : null,
                columns: [
                    <ColumnsTPL>{name:'Organizzazione', prop:'owned_organization', head:'hdr', cell:'organization',resize:1, sort:0, drag:1, flexGrow:3,         },
                    <ColumnsTPL>{name:'Forma giuridica',prop:'owned_organization.forma_giuridica', head:'hdr', cell:'text', resize:1, sort:0, drag:1, flexGrow:2 },
                    <ColumnsTPL>{name:'Quota detenuta %' ,prop:'percentage', head:'hdr', cell:'text', resize:1, sort:0, drag:1, flexGrow:1,                 },
                ]
            },
        }
    }

    private SUB_OWNERSHIPS_AS_OWNED(apipath: string, id: number): FormlyFieldConfig {
        return {
            wrappers: ['panel'],
            // expressionProperties: { 'templateOptions.hidden': '!formState.model.id' },
            hideExpression: '!formState.model.id',
            key: 'ownerships_as_owned', type: 'datatable',
            templateOptions: {
                label: 'É partecipato da',
                datasource: id ? apipath + '/' + id + '/ownerships_as_owned?active_status=current' : null,
                columns: [
                    <ColumnsTPL>{name:'Organizzazione', prop:'owner_organization', head:'hdr', cell:'organization',resize:1, sort:0, drag:1, flexGrow:3,         },
                    <ColumnsTPL>{name:'Persona'       , prop:'owner_person'      , head:'hdr', cell:'personlink'  ,resize:1, sort:0, drag:1, flexGrow:3,         },
                    <ColumnsTPL>{name:'Forma giuridica',prop:'owner_organization.forma_giuridica', head:'hdr', cell:'text', resize:1, sort:0, drag:1, flexGrow:2 },
                    <ColumnsTPL>{name:'Quota detenuta %' ,prop:'percentage', head:'hdr', cell:'text', resize:1, sort:0, drag:1, flexGrow:1,                 },
                ]
            },
        }
    }

    private SUB_ORGANIZATION_MEMBERSHIPS(apipath: string, id: number): FormlyFieldConfig {
        //@TODO: le liste sono tipicamente lunghe più di una pagina, il pulsante aggiungi nuova riga la mette in fondo
        return {
            wrappers: ['panel'],
            // expressionProperties: { 'templateOptions.hidden': '!formState.model.id' },
            hideExpression: '!formState.model.id',
            key: 'memberships', type: 'datatable',
            templateOptions: {
                label: 'Incarichi',
                datasource: id ? 'memberships?organization__id=' + id + '&page_size=200&ordering=-end_date&active_status=' : null,
                columns: [
                    <ColumnsTPL>{name:'Espandi'       , prop:null          , head:'hdr_exp', cell:'exp'        , resize:0, sort:0, drag:0, flexGrow:0, width:30, canAutoResize:0},
                    <ColumnsTPL>{name:'#'             , prop:'id'          , head:'hdr'    , cell:'id'         , resize:0, sort:0, drag:0, flexGrow:0, width:100, canAutoResize:0},
                    <ColumnsTPL>{name:'Organizzazione', prop:'organization', head:'hdr'    , cell:'organization',resize:1, sort:1, drag:1, flexGrow:3,                          },
                    <ColumnsTPL>{name:'Persona'       , prop:'person'      , head:'hdr'    , cell:'memberperson',resize:1, sort:1, drag:1, flexGrow:3,                          },
                    <ColumnsTPL>{name:'Ruolo'         , prop:'post.role'   , head:'hdr'    , cell:'text'       , resize:1, sort:1, drag:1, flexGrow:3,                          },
                    <ColumnsTPL>{name:'Descrizione'   , prop:'label'       , head:'hdr'    , cell:'text'       , resize:1, sort:1, drag:1, flexGrow:3,                          },
                    <ColumnsTPL>{name:'Data inizio'   , prop:'start_date'  , head:'hdr'    , cell:'ddMMyyyy'   , resize:1, sort:1, drag:1, flexGrow:1,                          },
                    <ColumnsTPL>{name:'Data fine'     , prop:'end_date'    , head:'hdr'    , cell:'ddMMyyyy'   , resize:1, sort:1, drag:1, flexGrow:1,                          },
                    <ColumnsTPL>{name:'Colonne'       , prop:''            , head:'hdr_sel', cell:'delete'     , resize:0, sort:0, drag:0, flexGrow:0, width:60, canAutoResize:0},
                ],
                row_detail: {
                    title: 'modifica incarico',
                    type: 'form',
                    datasource: 'memberships',
                    datasourceParams: (model) => { return model.id; },
                    allowAddRow: true
                }
            },
            //fieldArray: this.SUBFORM_SINGLE_MEMBERSHIP()
            fieldGroup: this.SUBFORM_ORGANIZATION_SINGLE_MEMBERSHIP().fieldGroup
        }
    }

    private SUB_SECONDARY_FILTERS(): FormlyFieldConfig {
        return {
            wrappers: ['subpanel'], templateOptions: {label: 'FILTRI'},
            fieldGroupClassName: 'row',
            fieldGroup: [
                {key:'active_from'  , type:'date'  , className:'col-3', templateOptions: {label:'Attivo a partire dal'} },
                {key:'active_to'    , type:'date'  , className:'col-3', templateOptions: {label:'Attivo fino al'} },
                {key:'active_at'    , type:'date'  , className:'col-3', templateOptions: {label:'Attivo in data'} },
                {key:'start_date'   , type:'date'  , className:'col-3', templateOptions: {label:'Data inizio il'} },
                {key:'end_date'     , type:'date'  , className:'col-3', templateOptions: {label:'Data fine il'} },
                {key:'updated_after', type:'date'  , className:'col-3', templateOptions: {label:'Aggiornato dopo il'} },
                {                     type:'spacer', className:'col-3'                                        },
                {key:'page_size'    , type:'select', className:'col-3', templateOptions: {label:'Risultati per pagina', options: this.pageSizeSelect}, defaultValue: this.pageSizeDefault },
            ]
        }
    }


    // @formatter:on
    /* tslint:enable */

    /* --------------------------------------------------------------------------------------------------------------------
     *
     * HELPER METHODS
     *
     * -------------------------------------------------------------------------------------------------------------------- */

      public fieldsAddCyKeyAttribute(fields) {
        fields.forEach((F) => {
          const label = get(F, 'templateOptions.label');
          if (label) {
            // console.log('label', label);
            set(F, 'templateOptions.attributes.data-cy', label)
          }
          if (Array.isArray(F.fieldGroup)) {
            // console.log(F.fieldGroup);
            F.fieldGroup.forEach((FG) => {
              if (FG.key) {
                set(FG, 'templateOptions.attributes.data-cy', FG.key);
              }
            });
          }

          if (F.fieldArray && Array.isArray(F.fieldArray.fieldGroup)) {
            F.fieldArray.fieldGroup.forEach((FG_FA_FG) => {
              if (FG_FA_FG.key) {
                set(FG_FA_FG, 'templateOptions.attributes.data-cy', FG_FA_FG.key);
              }
            });
          }
        });
      }

    /* --------------------------------------------------------------------------------------------------------------------
     *
     * LISTS DEFINITION
     *
     * -------------------------------------------------------------------------------------------------------------------- */
    public getListTemplate(apipath: string, context: any): ListTPL  {

        // const T = new TableTemplate();
        const c = context;
        let title = '', filters = [], actions = [], options = new ListTPLOptions();
        let columns = [];
        let prefetch = [];

        /* -== SUPPORTED HEADER TEMPLATES (see: abstract-list.component.ts, abstract-list.component.html) ==-
         *
         * T_hdr_exp = expander header, toggles the visibility of all rows details
         * T_hdr     = normal header, shows the column name
         * T_hdr_sel = column selector header, toggles the columns visibility
         *
         * -== SUPPORTED CELL TEMPLATES (see: abstract-list.component.ts, abstract-list.component.html) ==-
         *
         * T_exp             = row expander, toggles the visibility of the row details
         * T_id              = shows the row id, linked to the update form
         * T_identifiers     = shows a series of colored identity card icons
         * T_gender          = shows a single colored sexual gender icon
         * T_contact_details = shows a series social network and email icons
         * T_ddMMyyyy        = a date, shown as dd/MM/yyyy
         * T_ddMMyyyyHHmmss  = a date, shown as dd/MM/yyyy HH:mm:ss
         * T_empty           = an empty cell
         *
         */

        /* tslint:disable */
        // @formatter:off
        // ^^^^^^^^^^^^^^ see https://stackoverflow.com/a/19492318 for how to activate formatter disabling feature on IntelliJ based IDE
        switch (apipath) {

            case 'persons':

                title = 'Persone';

                columns = [
                    <ColumnsTPL>{name:'#'            , prop:'id'              , head:'hdr'    , cell:'id'             , resize:0, sort:0, drag:0, flexGrow:1, width: 80, canAutoResize:0},
                    <ColumnsTPL>{name:'##'           , prop:'identifiers'     , head:'hdr'    , cell:'identifiers'    , resize:0, sort:0, drag:1, flexGrow:2, width: 90, canAutoResize:0},
                    <ColumnsTPL>{name:'G'            , prop:'gender'          , head:'hdr'    , cell:'gender'         , resize:0, sort:0, drag:1, flexGrow:1, width: 40, canAutoResize:0},
                    <ColumnsTPL>{name:'Nome'         , prop:'given_name'      , head:'hdr'    , cell:'text'           , resize:1, sort:1, drag:1, flexGrow:3,                           },
                    <ColumnsTPL>{name:'Cognome'      , prop:'family_name'     , head:'hdr'    , cell:'text'           , resize:1, sort:1, drag:1, flexGrow:3,                           },
                    <ColumnsTPL>{name:'Luogo nascita', prop:'birth_location'  , head:'hdr'    , cell:'text'           , resize:1, sort:1, drag:1, flexGrow:3,                           },
                    <ColumnsTPL>{name:'Nascita'      , prop:'birth_date'      , head:'hdr'    , cell:'ddMMyyyy'       , resize:1, sort:1, drag:1, flexGrow:2,                           },
                    <ColumnsTPL>{name:'Decesso'      , prop:'death_date'      , head:'hdr'    , cell:'ddMMyyyy'       , resize:1, sort:1, drag:1, flexGrow:2,                           },
                    <ColumnsTPL>{name:'Contatti'     , prop:'contact_details' , head:'hdr'    , cell:'contact_details', resize:1, sort:0, drag:1, flexGrow:2,                           },
                    <ColumnsTPL>{name:'Creato'       , prop:'created_at'      , head:'hdr'    , cell:'ddMMyyyyHHmmss' , resize:1, sort:1, drag:1, flexGrow:2,                           },
                    <ColumnsTPL>{name:'Aggiornato'   , prop:'updated_at'      , head:'hdr'    , cell:'ddMMyyyyHHmmss' , resize:1, sort:1, drag:1, flexGrow:2,                           },
                    <ColumnsTPL>{name:'Colonne'      , prop:''                , head:'hdr_sel', cell:'delete'         , resize:0, sort:0, drag:0, flexGrow:1, width: 60, canAutoResize:0},
                ];

                filters = [
                    {fieldGroupClassName: 'row',
                        fieldGroup: [
                            {key:'search'       , type:'input' , className:'col-6', templateOptions: {label:'Cerca'} },
                            {key:'cf_id'        , type:'input' , className:'col-6', templateOptions: {label:'Codice fiscale'} },
                            {key:'active_status', type:'select', className:'col-3', templateOptions: {label:'Stato',
                                 options: [
                                     {label:'Tutti'   , value: ''     },
                                     {label:'Vivi'    , value:'alive'   },
                                     {label:'Deceduti', value:'deceased'}
                                 ]}
                            },
                            {key: 'gender', type: 'select', className: 'col-3', templateOptions: {label:'Genere',
                                options: [
                                    {label: 'Tutti'              , value: ''  },
                                    {label: 'Femmina'            , value: 'F' },
                                    {label: 'Maschio'            , value: 'M' },
                                    {label: 'Sconosciuto'        , value: 'gender_not_specified'}
                                ]}
                            },
                        ]
                    },
                    this.SUB_SECONDARY_FILTERS()
                ];
                break;

            case 'persons/akas':
                title = 'Gestione similarità persone';

                columns = [
                    {name:'N° Simil.'      , prop:'n_similarities'                     , head:'hdr_exp', cell:'exp_badge', resize:0, sort:1, drag:0, flexGrow:0, width: 65, canAutoResize:0},
                    {name:'G'              , prop:'loader_context.item.gender'         , head:'hdr'    , cell:'gender'   , resize:0, sort:0, drag:1, flexGrow:1, width: 40, canAutoResize:0},
                    {name:'Persona'        , prop:'loader_context.item'                , head:'hdr'    , cell:'person'   , resize:1, sort:0, drag:1, flexGrow:6         },
                    {name:'Incarichi'      , prop:'loader_context.item.memberships'    , head:'hdr'    , cell:'memberships', resize:1, sort:0, drag:0, flexGrow:9,                            },
/*
                    {name:'Organizzazione' , prop:'loader_context.item.org_name'       , head:'hdr'    , cell:'text'     , resize:1, sort:0, drag:1, flexGrow:3,                           },
                    {name:'Tipo carica'    , prop:'loader_context.item.role_type_label', head:'hdr'    , cell:'text'     , resize:1, sort:0, drag:1, flexGrow:3,                           },
                    {name:'Data inizio'    , prop:'loader_context.item.start_date'     , head:'hdr'    , cell:'ddMMyyyy' , resize:1, sort:1, drag:1, flexGrow:2,                           },
                    {name:'Data fine'      , prop:'loader_context.item.end_date'       , head:'hdr'    , cell:'ddMMyyyy' , resize:1, sort:1, drag:1, flexGrow:2,                           },
*/
                    {name:'Data similarità', prop:'created_at'                         , head:'hdr'    , cell:'ddMMyyyy' , resize:1, sort:1, drag:1, flexGrow:2,                           },
                    {name:'Generatore'     , prop:'loader_context.data_source'         , head:'hdr'    , cell:'text'     , resize:1, sort:1, drag:1, flexGrow:3,                           },
                    {name:'Azioni'         , prop:''                                   , head:'hdr'    , cell:'simil_add', resize:0, sort:0, drag:0, flexGrow:0, width: 80, canAutoResize:0 },
                    {name:'Colonne'        , prop:null                                 , head:'hdr_sel', cell:'empty'    , resize:0, sort:0, drag:0, flexGrow:1, width: 60, canAutoResize:0},
                ];

                filters = [
                    {fieldGroupClassName: 'row',
                        fieldGroup: [
                            {key:'search'            , type:'input' , className:'col-8', templateOptions: {label:'Cerca'} },
                            {key:'search_given_name' , type:'input' , className:'col-4', templateOptions: {label:'Nome'} },
                            {key:'search_family_name', type:'input' , className:'col-4', templateOptions: {label:'Cognome'} },
                            {key:'op_context'        , type:'select', className:'col-4', templateOptions: {label:'Contesto',
                                options: [
                                    {label: 'Tutti'              , value: ''  },
                                    {label: 'Regioni'            , value: 'regioni'  },
                                    {label: 'Province'           , value: 'province' },
                                    {label: 'Città metropolitane', value: 'metro'   },
                                    {label: 'Comuni'             , value: 'com'  }
                                ]}, defaultValue: ''
                            },
                            {key:'is_resolved', type:'select', className:'col-4', templateOptions: {label:'Stato',
                                options: [
                                    {label: 'Tutte'      , value: ''  },
                                    {label: 'Risolte'    , value: 'true' },
                                    {label: 'Non risolte', value: 'false' },
                                ]}, defaultValue: 'false'
                            }
                        ]
                    },
                    {
                        wrappers: ['subpanel'], templateOptions: {label: 'FILTRI'},
                        fieldGroupClassName: 'row',
                        fieldGroup: [
                            {                     type:'spacer', className:'col-20'                                        },
                            {key:'page_size'    , type:'select', className:'col-4', templateOptions: {label:'Risultati per pagina', options: this.pageSizeSelect}, defaultValue: this.pageSizeDefault },
                        ]
                    }
                ];

                options = {
                    supports_creation: false,
                    row_detail: {
                        title: 'Similarità trovate',
                        type: 'table',
                        rowHeight: (row) => { return row.memberships.length * 50 },
                        datasource: 'persons/search',
                        datasourceParams: (model) => { return model.search_params; },
                        fields: [
                            {name:'Punti'    , prop:'score'      , head:'hdr', cell:'sep_num'    , resize:0, sort:0, drag:0, flexGrow:0, width: 65, canAutoResize:0 },
                            {name:'G'        , prop:'gender'     , head:'hdr', cell:'gender'     , resize:0, sort:0, drag:1, flexGrow:1, width: 40, canAutoResize:0},
                            {name:'Persona'  , prop:''           , head:'hdr', cell:'personlink' , resize:1, sort:0, drag:0, flexGrow:9,                            },
                            {name:'Incarichi', prop:'memberships', head:'hdr', cell:'memberships', resize:1, sort:0, drag:0, flexGrow:9,                            },
                            {name:'Azioni'   , prop:''           , head:'hdr', cell:'simil_bind' , resize:0, sort:0, drag:0, flexGrow:0, width: 90, canAutoResize:0 }
                        ]
                    },
                };
                break;

            case 'organizations':

                title = 'Organizzazioni';

                columns = [
                    /*{name:'Espandi'      , prop:null              , head:'hdr_exp', cell:'exp'        , resize:0, sort:0, drag:0, flexGrow:1, width: 40, canAutoResize:0},*/
                    {name:'#'            , prop:'id'              , head:'hdr'    , cell:'id'         , resize:0, sort:0, drag:0, flexGrow:1, width: 80, canAutoResize:0},
                    {name:'##'           , prop:'identifiers'     , head:'hdr'    , cell:'identifiers', resize:0, sort:0, drag:1, flexGrow:2, width: 130, canAutoResize:0},
                    {name:'Nome'         , prop:'name'            , head:'hdr'    , cell:'text'       , resize:1, sort:1, drag:1, flexGrow:3,                           },
                    {name:'Fondazione'   , prop:'founding_date'   , head:'hdr'    , cell:'ddMMyyyy'   , resize:1, sort:1, drag:1, flexGrow:2,                           },
                    {name:'Dissoluzione' , prop:'dissolution_date', head:'hdr'    , cell:'ddMMyyyy'   , resize:1, sort:1, drag:1, flexGrow:2,                           },
                    {name:'Incarichi'    , prop:null              , head:'hdr'    , cell:'org_members', resize:1, sort:0, drag:1, flexGrow:1, width: 200, canAutoResize:0},
                    {name:'Colonne'      , prop:''                , head:'hdr_sel', cell:'delete'     , resize:0, sort:0, drag:0, flexGrow:1, width: 60, canAutoResize:0},
                ];

                filters = [
                    {fieldGroupClassName: 'row',
                        fieldGroup: [
                            {key:'search'           , type:'input'  , className:'col-4', templateOptions: {label:'Cerca'} },
                            {key:'forma_giuridica', type:'select2', className:'col-12',templateOptions: {label:'Forma giuridica', valueProp: 'descr', labelProp: 'descr',
                                    codelistPath: apipath + '/classifications', codeListParams: {scheme: 'FORMA_GIURIDICA_OP', page_size: 200}, codeListPaged: true}
                            },
                            //{key:'forma_giuridica_op_id', type:'select2', className:'col-18', templateOptions: {label:'Forma giuridica OP', codelistPath: apipath + '/classifications', valueProp: 'id', labelProp: 'descr', codeListParams: {scheme: 'FORMA_GIURIDICA_ISTAT_BDAP'}}, defaultValue: ''},
                            {key:'ordering'      , type:'select', className:'col-4', templateOptions: {label:'Altri ordinamenti',
                                options: [
                                    {label: 'Tutte'              , value: ''  },
                                    {label: 'Abitanti'           , value: '-area__inhabitants' },
                                ]}, defaultValue: ''
                            },
                        ]
                    },
                    {
                        wrappers: ['subpanel'], templateOptions: {label: 'FILTRI'},
                        fieldGroupClassName: 'row',
                        fieldGroup: [
                            {key:'scheme'           , type:'select2', className:'col-4', templateOptions: {label:'Tipo classificazione', codelistPath: apipath + '/classification_types'}, defaultValue: ''},
                            {key:'classification_id', type:'select2', className:'col-20',
                                templateOptions: {label:'Classificazione', valueProp: 'id', labelProp: 'descr',
                                    filterField: 'scheme', codelistPath: apipath + '/classifications', codeListParams: {scheme: '', page_size: 200}, codeListPaged: true}
                            },
                            {key:'active_from'  , type:'date'  , className:'col-4', templateOptions: {label:'Attivo dal'} },
                            {key:'active_to'    , type:'date'  , className:'col-4', templateOptions: {label:'Attivo al'} },
                            {key:'active_at'    , type:'date'  , className:'col-4', templateOptions: {label:'Attivo il'} },
                            {key:'updated_after', type:'date'  , className:'col-4', templateOptions: {label:'Aggiornato dopo il'} },
                            {                     type:'spacer', className:'col-4'                                        },
                            {key:'page_size'    , type:'select', className:'col-4', templateOptions: {label:'Risultati per pagina', options: this.pageSizeSelect}, defaultValue: this.pageSizeDefault },
                            {key:'min_employees'     , type:'number', className:'col-4', templateOptions: {label:'Min dipendenti'} },
                            {key:'max_employees'     , type:'number', className:'col-4', templateOptions: {label:'Max dipendenti'} },
                            {key:'min_revenue'       , type:'number', className:'col-4', templateOptions: {label:'Min fatturato'} },
                            {key:'max_revenue'       , type:'number', className:'col-4', templateOptions: {label:'Max fatturato'} },
                            {key:'min_capital_stock' , type:'number', className:'col-4', templateOptions: {label:'Min capitalizzazione'} },
                            {key:'max_capital_stock' , type:'number', className:'col-4', templateOptions: {label:'Max capitalizzazione'} },
                        ]
                    }
                ];
                break;

            case 'areas':

                title = 'Aree';

                columns = [
                    {name:'#'              , prop:'id'                  , head:'hdr'    , cell:'id'         , resize:0, sort:0, drag:0, flexGrow:1, width: 80, canAutoResize:0},
                    {name:'##'             , prop:'identifiers'         , head:'hdr'    , cell:'identifiers', resize:0, sort:0, drag:1, flexGrow:2, width:120, canAutoResize:0},
                    {name:'Nome'           , prop:'name'                , head:'hdr'    , cell:'text'       , resize:1, sort:1, drag:1, flexGrow:3,                           },
                    {name:'Abitanti'       , prop:'inhabitants'         , head:'hdr'    , cell:'sep_num'    , resize:1, sort:1, drag:1, flexGrow:2,                           },
                    {name:'Classe Geonames', prop:'classification'      , head:'hdr'    , cell:'text'       , resize:1, sort:0, drag:1, flexGrow:2,                           },
                    {name:'Classe ISTAT'   , prop:'istat_classification', head:'hdr'    , cell:'text'       , resize:1, sort:0, drag:1, flexGrow:2,                           },
                    {name:'ID'             , prop:'identifier'          , head:'hdr'    , cell:'text'       , resize:1, sort:0, drag:1, flexGrow:2,                           },
                    {name:'Data inizio'    , prop:'start_date'          , head:'hdr'    , cell:'ddMMyyyy'   , resize:1, sort:1, drag:1, flexGrow:2,                           },
                    {name:'Data fine'      , prop:'end_date'            , head:'hdr'    , cell:'ddMMyyyy'   , resize:1, sort:1, drag:1, flexGrow:2,                           },
                    {name:'Colonne'        , prop:null                  , head:'hdr_sel', cell:'empty'      , resize:0, sort:0, drag:0, flexGrow:1, width: 60, canAutoResize:0},
                ];

                filters = [
                    {fieldGroupClassName: 'row',
                        fieldGroup: [
                            {key:'search'              , type:'input' , className:'col-4', templateOptions: {label:'Cerca'} },
                            {key:'min_inhabitants'     , type:'number', className:'col-4', templateOptions: {label:'Min abitanti'} },
                            {key:'max_inhabitants'     , type:'number', className:'col-4', templateOptions: {label:'Max abitanti'} },
                            {key:'istat_classification', type:'select', className:'col-4', templateOptions: {label:'Classe ISTAT',
                                options: [
                                    {label: 'Tutte'              , value: ''  },
                                    {label: 'Ripartizione'       , value: 'RIP' },
                                    {label: 'Regione'            , value: 'REG' },
                                    {label: 'Provincia'          , value: 'PROV'},
                                    {label: 'Città metropolitana', value: 'CM'  },
                                    {label: 'Comune'             , value: 'COM' },
                                ]}, defaultValue: ''
                            },
                            {key:'classification'       , type:'select', className:'col-4', templateOptions: {label:'Classe GeoNames',
                                 options: [
                                     {label: 'Tutte'              , value: ''    },
                                     {label: 'Regione economica'  , value: 'RGNE'},
                                     {label: 'Amministrativa 1'   , value: 'ADM1'},
                                     {label: 'Amministrativa 2'   , value: 'ADM2'},
                                     {label: 'Amministrativa 3'   , value: 'ADM3'},
                                 ]}, defaultValue: ''
                            },
                            {key:'page_size'  , type:'select', className:'col-4', templateOptions: {label:'Risultati per pagina',
                                    options: this.pageSizeSelect}, defaultValue: this.pageSizeDefault
                            }
                        ]
                    },
                    this.SUB_SECONDARY_FILTERS()
                ];
                break;

            case 'keyevents':

                title = 'Eventi';

                columns = [
                    /*{name:'#'              , prop:'id'                  , head:'hdr'    , cell:'id'         , resize:0, sort:0, drag:0, flexGrow:1, width: 80, canAutoResize:0},*/
                    {name:'Nome'           , prop:'name'                , head:'hdr'    , cell:'text'       , resize:1, sort:0, drag:1, flexGrow:3,                           },
                    {name:'ID'             , prop:'identifier'          , head:'hdr'    , cell:'text'       , resize:1, sort:0, drag:1, flexGrow:2,                           },
                    {name:'Data inizio'    , prop:'start_date'          , head:'hdr'    , cell:'ddMMyyyy'   , resize:1, sort:1, drag:1, flexGrow:2,                           },
                    {name:'Data fine'      , prop:'end_date'            , head:'hdr'    , cell:'ddMMyyyy'   , resize:1, sort:0, drag:1, flexGrow:2,                           },
                    {name:'Motivo fine'    , prop:'end_reason'          , head:'hdr'    , cell:'text'       , resize:1, sort:0, drag:1, flexGrow:2,                           },
                    {name:'Colonne'        , prop:null                  , head:'hdr_sel', cell:'empty'      , resize:0, sort:0, drag:0, flexGrow:1, width: 60, canAutoResize:0},
                ];

                filters = [
                    {fieldGroupClassName: 'row',
                        fieldGroup: [
                            {key:'name'                , type:'input' , className:'col-8', templateOptions: {label:'Nome'} },
                            {key:'event_type'          , type:'select', className:'col-8', templateOptions: {label:'Tipo evento',
                                options: [
                                    {value: 'ELE',       label: 'Election round'},
                                    {value: 'ELE-POL',   label: 'National election'},
                                    {value: 'ELE-EU',    label: 'European election'},
                                    {value: 'ELE-REG',   label: 'Regional election'},
                                    {value: 'ELE-METRO', label: 'Metropolitan election'},
                                    {value: 'ELE-PROV',  label: 'Provincial election'},
                                    {value: 'ELE-COM',   label: 'Comunal election'},
                                    {value: 'ITL',       label: 'IT legislature'},
                                    {value: 'EUL',       label: 'EU legislature'},
                                    {value: 'XAD',       label: 'External administration'}
                                ]}, defaultValue: ''
                            },
                            {                     type:'spacer', className:'col-4'                                        },
                            {key:'page_size'  , type:'select', className:'col-4', templateOptions: {label:'Risultati per pagina',
                                    options: this.pageSizeSelect}, defaultValue: this.pageSizeDefault
                            }
                        ]
                    }
                ];
                break;

            case 'memberships':

                title = 'Incarichi';

                columns = [
                    <ColumnsTPL>{name:'Espandi'       , prop:null          , head:'hdr_exp', cell:'exp'        , resize:0, sort:0, drag:0, flexGrow:0, width:30, canAutoResize:0},
                    <ColumnsTPL>{                                            headerCheckboxable:true , checkboxable:true         , resize:0, sort:0, drag:0, flexGrow:0, width:30, canAutoResize:0},
                    <ColumnsTPL>{name:'#'             , prop:'id'          , head:'hdr'    , cell:'id'         , resize:0, sort:0, drag:0, flexGrow:0, width:80, canAutoResize:0},
                    <ColumnsTPL>{name:'Organizzazione', prop:'organization', head:'hdr'    , cell:'organization',resize:1, sort:1, drag:1, flexGrow:3, sortBy: 'organization__name' },
                    <ColumnsTPL>{name:'Persona'       , prop:'person'      , head:'hdr'    , cell:'personlink' , resize:1, sort:1, drag:1, flexGrow:3, sortBy: 'person__sort_name' },
                    <ColumnsTPL>{name:'Ruolo'         , prop:'post.role'   , head:'hdr'    , cell:'text'       , resize:1, sort:1, drag:1, flexGrow:3, sortBy: 'role'           },
                    <ColumnsTPL>{name:'Descrizione'   , prop:'label'       , head:'hdr'    , cell:'text'       , resize:1, sort:0, drag:1, flexGrow:3,                          },
                    <ColumnsTPL>{name:'Data inizio'   , prop:'start_date'  , head:'hdr'    , cell:'ddMMyyyy'   , resize:1, sort:1, drag:1, flexGrow:1,                          },
                    <ColumnsTPL>{name:'Data fine'     , prop:'end_date'    , head:'hdr'    , cell:'ddMMyyyy'   , resize:1, sort:1, drag:1, flexGrow:1,                          },
                    <ColumnsTPL>{name:'Colonne'       , prop:''            , head:'hdr_sel', cell:'delete'     , resize:0, sort:0, drag:0, flexGrow:0, width:60, canAutoResize:0},
                ];

                filters = [
                    {fieldGroupClassName: 'row',
                        fieldGroup: [
                            {key:'search'          , type:'input'  , className:'col-8', templateOptions: {label:'Cerca'} },
                            {key:'person__id'      , type:'select2', className:'col-8', templateOptions: {label:'Persona', codelistPath: 'persons', codeListParams: {page_size: 15}, codeListPaged: true, labelProp: 'total_label'} },
                            {                        type:'spacer',  className:'col-4'                                        },
                            {key:'active_status'   , type:'select' , className:'col-4', templateOptions: {label:'Stato attivo',
                                 options: [
                                     {label:'Tutti'  , value: ''      },
                                     {label:'Attuali', value:'current'},
                                     {label:'Passati', value:'past'   }
                                 ]},
                                defaultValue: 'current'
                            },
                            {key:'forma_giuridica', type:'select2', className:'col-8',templateOptions: {label:'Forma giuridica', valueProp: 'descr', labelProp: 'descr',
                                    codelistPath: 'organizations/classifications', codeListParams: {scheme: 'FORMA_GIURIDICA_OP', page_size: 200}, codeListPaged: true}
                            },
                            {key:'organization__id', type:'select2', className:'col-16', templateOptions: {label:'Organizzazione',
                                    filterField: 'forma_giuridica', codelistPath: 'organizations', codeListParams: {forma_giuridica: '', page_size: 15}, codeListPaged: true} }
                        ]
                    },
                    this.SUB_SECONDARY_FILTERS()
                ];

                actions = [
                    {fieldGroupClassName: 'row',
                        fieldGroup: [
                            {key:'action'       , type:'select', className:'col-8', templateOptions: {label:'Azioni',
                                 options: [
                                     {label:'Azioni di gruppo'       , value:''           },
                                     {label:'Modifica data fine'     , value:'change_end_date'   },
                                     {label:'Modifica data inizio'   , value:'change_start_date' },
                                     {label:'Modifica lista elezione', value:'change_electoral_list_descr_tmp' },
                                     {label:'Modifica data elezione' , value:'change_electoral_event_date_tmp' }
                                 ]},
                                defaultValue: ''
                            },
                            {key:'start_date'              , type:'date'  , className:'col-4'   , templateOptions: {label:'Data inizio'},
                                hideExpression: (model: any) => { return model.action !== 'change_start_date'; }, defaultValue: null
                            },
                            {key:'end_date'                , type:'date'  , className:'col-4'   , templateOptions: {label:'Data fine'},
                                hideExpression: (model: any) => { return model.action !== 'change_end_date'; }, defaultValue: null,
                                expressionProperties : {
                                  'templateOptions.required' : 'model.end_reason !== null',
                                }
                            },
                            {key:'end_reason'              , type:'input', className:'col-12'    , templateOptions: {label:'Motivo'},
                                hideExpression: (model: any) => { return model.action !== 'change_end_date'; }, defaultValue: null,
                                expressionProperties : {
                                  'templateOptions.required' : 'model.end_date !== null'
                                }
                            },
                            {key:'electoral_list_descr_tmp', type:'input', className:'col-12'    , templateOptions: {label:'Lista di elezione'},
                                hideExpression: (model: any) => { return model.action !== 'change_electoral_list_descr_tmp'; }, defaultValue: null
                            },
                            {key:'electoral_event_date_tmp', type:'date'  , className:'col-4'   , templateOptions: {label:'Data di elezione'},
                                hideExpression: (model: any) => { return model.action !== 'change_electoral_event_date_tmp'; }, defaultValue: null
                            }
                        ]
                    }
                ];

                options = {
                    supports_creation: true,
                    creation_params: { // maps list filter parameters to prefilled edit values
                      'organization': 'organization__id',
                      'person': 'person__id',
                    },
                    csv_export_opts: { // see https://github.com/zemirco/json2csv for available options
                      delimiter: '\t',
                      fields: ['id','organization.name','person.sort_name','person.birth_date','person.birth_location','role','label','start_date','end_date']
                    },
                    row_detail: {
                        title: 'modifica incarico',
                        type: 'form',
                        datasource: 'memberships',
                        datasourceParams: (model) => { return model.id; },
                        fieldGroup: this.SUBFORM_PERSON_SINGLE_MEMBERSHIP().fieldGroup
                    }
                };
                break;

            case 'electoral_results':

              title = 'Risultati elettorali';

              columns = [
                <ColumnsTPL>{name:'Espandi'       , prop:null          , head:'hdr_exp', cell:'exp'        , resize:0, sort:0, drag:0, flexGrow:0, width:30, canAutoResize:0},
                <ColumnsTPL>{name:'#'                  , prop:'id'          , head:'hdr'    , cell:'id'         , resize:0, sort:0, drag:0, flexGrow:0, width:80, canAutoResize:0},
                <ColumnsTPL>{name:'Evento'             , prop:'electoral_event.name', head:'hdr'    , cell:'text',resize:1, sort:1, drag:1, flexGrow:3, sortBy: 'organization__name' },
                <ColumnsTPL>{name:'Istituzione'        , prop:'institution.name'    , head:'hdr'    , cell:'text' , resize:1, sort:1, drag:1, flexGrow:3, sortBy: 'person__sort_name' },
                <ColumnsTPL>{name:'Collegio elettorale', prop:'constituency.name'    , head:'hdr'    , cell:'text'       , resize:1, sort:1, drag:1, flexGrow:3, sortBy: 'role'           },
              ];

              filters = [
                {fieldGroupClassName: 'row',
                  fieldGroup: [
                    {key:'electoral_event__id', type:'select2', className:'col-7',templateOptions: {label:'Evento', valueProp: 'id', labelProp: 'name',
                        filterField: ['institution__id', 'constituency__id'], codelistPath: 'electoral_results/events', codeListParams: {page_size: 200}, codeListPaged: true} },
                    {key:'institution__id', type:'select2', className:'col-7', templateOptions: {label:'Istituzione',
                        filterField: ['electoral_event__id', 'constituency__id'], codelistPath: 'electoral_results/institutions', codeListParams: {constituency__id: '', page_size: 30}, codeListPaged: true} },
                    {key:'constituency__id', type:'select2', className:'col-7', templateOptions: {label:'Collegio elettorale',
                        filterField: ['electoral_event__id', 'institution__id'], codelistPath: 'electoral_results/constituencies', codeListParams: {institution__id: '', page_size: 30}, codeListPaged: true} },
                    {key:'page_size'    , type:'select', className:'col-3', templateOptions: {label:'Risultati per pagina', options: this.pageSizeSelect}, defaultValue: this.pageSizeDefault },
                  ]
                },
              ];

              options = {
                supports_creation: false,
                row_detail: {
                  title: 'Liste',
                  datasource: (model) => { return 'electoral_results/' + model.id + '/list_results'; },
                  datasourceParams: {},
                  type: 'table',
                  fields: [
                    {name:'Nome'           , prop:'name'            , head:'hdr', cell:'text' , resize:1, sort:0, drag:0, flexGrow:9,                           },
                    {name:'Colore politico', prop:'political_colour', head:'hdr', cell:'patchselect' , resize:1, sort:0, drag:0, flexGrow:9,
                      templateOptions: { static_data_prop: 'political_colours', save_path: 'list_results'} },
                    {name:'Partiti'        , prop:'parties'         , head:'hdr', cell:'patchselect' , resize:1, sort:0, drag:0, flexGrow:9,
                      templateOptions: { multiple: true, static_data_prop: 'parties', save_path: 'list_results' }},
                  ]
                  // type: 'form',
                  // fieldGroup: this.SUBFORM_ELECTORAL_RESULT_SINGLE_LIST_RESULTS().fieldArray.fieldGroup
                }
              };

              prefetch = [
                  {
                    name: 'parties',
                    codelistPath: 'organizations',
                    codelistParams: {page_size:200, forma_giuridica:'Partito/Movimento politico'},
                  },
                  {
                    name: 'political_colours',
                    codelistPath: 'political_colours',
                    codelistParams: {}
                  }
              ];
            break;

        }
        // @formatter:on
        /* tslint:enable */

        if (this.debug) {
          this.fieldsAddCyKeyAttribute(filters);
        }

        return {title, columns, filters, actions, options, prefetch};
    }



    /* --------------------------------------------------------------------------------------------------------------------
     *
     * FORMS DEFINITION
     *
     * -------------------------------------------------------------------------------------------------------------------- */

    public getFormTemplate(apipath: string, id: number, alive: boolean): FormTPL {

        let title = '';
        let fields: FormlyFieldConfig[] = [];
        let options: FormlyFormOptions = {};

        /* tslint:disable */
        // @formatter:off
        switch (apipath) {
            case 'persons':

                title = 'Persone';

                fields = [
                    // HEADER
                    {type: 'header', templateOptions: {
                            headerOptions: {
                                section: title +' / ',
                                title: ['given_name','family_name'],
                                subtitle: ['birth_location','birth_date'],
                                image: 'image'
                            }
                        }
                    },
                    // ANAGRAFICA
                    {
                        wrappers: ['panel'], templateOptions: {label: 'Anagrafica'},
                        fieldGroupClassName: 'row',
                        fieldGroup: [
                            {key: 'given_name'         , type: 'input'  , className: 'col-4', templateOptions: {label: 'Nome'   , required: true}},
                            {key: 'family_name'        , type: 'input'  , className: 'col-4', templateOptions: {label: 'Cognome', required: true}},
                            {key: 'birth_location_area', type: 'select2', className: 'col-7', templateOptions: {label: 'Luogo di nascita', codelistPath: 'areas', codeListPaged: true}},
                            {key: 'birth_date'         , type: 'date'   , className: 'col-3', templateOptions: {label: 'Data di nascita'} },
                            {key: 'death_date'         , type: 'date'   , className: 'col-3', templateOptions: {label: 'Data di decesso'} },
                            {key: 'gender'             , type: 'radio'  , className: 'col-3', templateOptions: {label: 'Genere',
                                    options: [
                                        {label: 'Maschio', value: 'M'},
                                        {label: 'Femmina', value: 'F'}
                                    ]
                                }
                            }
                        ]
                    },
                    // SIMILARITA' TROVATE
                    {
                        wrappers: ['panel'],
                        hideExpression: '!formState.similarityShow',
                        key: 'ACTIONBUS', type: 'datatable',
                        templateOptions: {
                            label: 'Similarità trovate',
                            actionBusChannel: 'similarity_check',
                            columns: [
                                        {name:'Punti'    , prop:'score'      , head:'hdr', cell:'sep_num'    , resize:0, sort:0, drag:0, flexGrow:0, width: 65, canAutoResize:0 },
                                        {name:'G'        , prop:'gender'     , head:'hdr', cell:'gender'     , resize:0, sort:0, drag:1, flexGrow:1, width: 40, canAutoResize:0},
                                        {name:'Persona'  , prop:''           , head:'hdr', cell:'personlink' , resize:1, sort:0, drag:0, flexGrow:9,                            },
                                        {name:'Incarichi', prop:'memberships', head:'hdr', cell:'memberships', resize:1, sort:0, drag:0, flexGrow:9,                            },
                            ]
                        },
                        fieldArray: {}
                    },
                    // SIMILARITA' CARICAMENTO
                    {
                        hideExpression: '!formState.similarityLoading',
                        type: 'message', templateOptions: {label: 'ricerca similarità in corso'}
                    },
                    // INFORMAZIONI ESTESE
                    {
                        wrappers: ['panel'], templateOptions: {label: 'Informazioni estese'},
                        // expressionProperties: { 'templateOptions.hidden': '!formState.model.id' },
                        hideExpression: '!formState.model.id',
                        fieldGroupClassName: 'row',
                        fieldGroup: [
                            {key: 'education_level'         ,type: 'select2' ,className: 'col-12', templateOptions: {label: 'Educazione'             , codelistPath: 'persons/education_levels'    , codeListPaged: true}},
                            /*{key: 'original_education_level',type: 'select2' ,className: 'col-12', templateOptions: {label: 'Educazione (originale)' , codelistPath: 'persons/original_education_levels', codeListPaged: true}},*/
                            {key: 'original_profession'     ,type: 'select2' ,className: 'col-12', templateOptions: {label: 'Professione (originale)', codelistPath: 'persons/original_professions', codeListPaged: true }},
                            {key: 'email'                   ,type: 'email'   ,className: 'col-12', templateOptions: {label: 'E-mail'}},
                            {key: 'image'                   ,type: 'url'     ,className: 'col-12', templateOptions: {label: 'URL immagine'}},
                            {key: 'biography'               ,type: 'textarea',className: 'col-24', templateOptions: {label: 'Biografia', rows: 2}}

                        ]
                    },
                    // INCARICHI
                    {
                        fieldGroupClassName: 'row',
                        fieldGroup: [
                            {
                                type: 'link',
                                hideExpression: '!formState.model.n_current_memberships',
                                className: 'col-12',
                                templateOptions: {
                                    apipath: '/memberships',
                                    query: {
                                        active_status: 'current',
                                        page_size: 15,
                                        person__id: ['id']
                                    },
                                    title: ['n_current_memberships'],
                                    before_title: 'incarichi attuali: ',
                                    style_class: ''
                                }
                            },
                            {
                                type: 'link',
                                hideExpression: '!formState.model.n_past_memberships',
                                className: 'col-12',
                                templateOptions: {
                                    apipath: '/memberships',
                                    query: {
                                        active_status: 'past',
                                        page_size: 15,
                                        person__id: ['id']
                                    },
                                    title: ['n_past_memberships'],
                                    before_title: 'incarichi passati: ',
                                    style_class: ''
                                }
                            }
                        ]
                    },
                    this.SUB_PERSON_MEMBERSHIPS(apipath, id),
                    this.SUB_PERSON_OWNERSHIPS(apipath, id),
                    this.SUB_IDENTIFIERS(apipath),
                    this.SUB_CLASSIFICATIONS(apipath, id, alive),
                    this.SUB_CONTACT_DETAILS(),
                    this.SUB_LINKS(),
                    this.SUB_SOURCES(),
                    this.SUB_OTHERNAMES(apipath)
                ];

                options = {
                     formState: {
                        allowDelete: true,
                        similarityCheck: {
                                fields: ['given_name','family_name','birth_location_area','birth_date','gender']
                        }
                     }
                };
                break;

            case 'organizations':
                title = 'Organizzazioni';

                fields = [
                    {type: 'header', templateOptions: {
                            headerOptions: {
                                section: title +' / ',
                                title: ['name'],
                                image: 'image'
                            }
                        }
                    },
                    {
                        type: 'link', hideExpression: '!formState.model.parent',
                        templateOptions: {
                            title: ['parent.name'],
                            before_title: 'parte di: ',
                            apipath: '/'+ apipath + '/edit/',
                            link: ['parent.id']
                        }
                    },
                    {
                        fieldGroupClassName: 'clearfix',
                        fieldGroup: [
                            {type: 'link', className: 'float-left', templateOptions: {
                                    apipath: '/memberships',
                                    query: {
                                        active_status: '',
                                        page_size: 15,
                                        organization__id: ['id']
                                    },
                                    icon: 'icon-graduation',
                                    title: ['n_memberships'],
                                    title_alt: 'Incarichi totali',
                                    before_title: ' Incarichi: ',
                                    style_class: ''
                                }
                            },
                            {type: 'link', className: 'float-left', templateOptions: {
                                    apipath: '/memberships',
                                    query: {
                                        active_status: 'current',
                                        page_size: 15,
                                        organization__id: ['id']
                                    },
                                    title: ['n_current_memberships'],
                                    title_alt: 'Incarichi attuali',
                                    before_title: ' (',
                                    style_class: ''
                                }
                            },
                            {type: 'link', className: 'float-left', templateOptions: {
                                    apipath: '/memberships',
                                    query: {
                                        active_status: 'past',
                                        page_size: 15,
                                        organization__id: ['id']
                                    },
                                    title: ['n_past_memberships'],
                                    title_alt: 'Incarichi passati',
                                    before_title: ' + ',
                                    style_class: '',
                                    after_title: ')'
                                }
                            },
                            {type: 'link', className: 'float-left mr-5', templateOptions: {
                                    apipath: '/memberships/edit',
                                    query: {
                                        organization: ['id']
                                    },
                                    title: ['crea nuovo incarico'],
                                    title_alt: 'crea nuovo incarico',
                                    before_title: '',
                                    style_class: 'btn btn-sm btn-outline-primary ml-4',
                                    after_title: ''
                                }
                            },
                            {type: 'read_number', className: 'float-left mr-3', templateOptions: {
                                    icon: 'fal fa-euro-sign',
                                    number: ['revenue'],
                                    number_alt: 'Fatturato',
                                    before_title: ' ',
                                    style_class: ''
                                }
                            },
                            {type: 'read_number', className: 'float-left mr-3', templateOptions: {
                                    icon: 'fal fa-piggy-bank',
                                    number: ['capital_stock'],
                                    number_alt: 'Capitalizzazione',
                                    before_title: ' ',
                                    style_class: ''
                                }
                            },
                            {type: 'read_number', className: 'float-left', templateOptions: {
                                    icon: 'fal fa-users',
                                    number: ['employees'],
                                    number_alt: 'Dipendenti',
                                    before_title: ' ',
                                    style_class: ''
                                }
                            },
                        ]
                    },
                    {
                        wrappers: ['panel'], templateOptions: {label: 'Anagrafica'}, fieldGroupClassName: 'row',
                        fieldGroup: [
                            {key: 'name'            , type: 'input'  , className: 'col-8', templateOptions: {label: 'Nome', required: true}},
                            {key: 'area'            , type: 'select2', className: 'col-4' , templateOptions: {label: 'Area geografica', codelistPath: 'areas', codeListPaged: true, codeListParams: {}}},
                            {key: 'founding_date'   , type: 'date'   , className: 'col-3' , templateOptions: {label: 'Fondazione'}},
                            {key: 'dissolution_date', type: 'date'   , className: 'col-3' , templateOptions: {label: 'Dissoluzione'}},
                            {key: 'end_reason'      , type: 'input'  , className: 'col-6' , templateOptions: {label: 'Motivo dissoluzione'}},
                            {key: 'identifier'      , type: 'input'  , className: 'col-8', templateOptions: {label: 'Identificatore principale (CF, PIVA, ...)'}},
                            {key: 'classification'  , type: 'input'  , className: 'col-8', templateOptions: {label: 'Classificazione principale'}},
                            {key: 'parent'          , type: 'select2', className: 'col-8', templateOptions: {label: 'Organizzazione superiore', codelistPath: 'organizations'}}, //TODO: creare validatore id !== id entita attuale
                            {key: 'image'           , type: 'url'    , className: 'col-24', templateOptions: {label: 'URL immagine'}},
                        ]
                    },
                    // this.SUB_ORGANIZATION_MEMBERSHIPS(apipath, id), //@TODO: vedi definizione funzione
                    this.SUB_KEY_EVENTS(apipath),
                    this.SUB_SUBORGS(apipath),
                    this.SUB_OWNERSHIPS_AS_OWNER(apipath, id),
                    this.SUB_OWNERSHIPS_AS_OWNED(apipath, id),
                    this.SUB_CONTACT_DETAILS(),
                    this.SUB_CLASSIFICATIONS(apipath, id, alive),
                    // this.SUB_ORGANIZATION_MEMBERSHIPS(apipath, id),
                    this.SUB_IDENTIFIERS(apipath),
                    this.SUB_LINKS(),
                    this.SUB_SOURCES(),
                    this.SUB_OTHERNAMES(apipath)
                ];
                break;

            case 'areas':
                title = 'Aree';

                fields = [
                    {type: 'header', templateOptions: {
                            headerOptions: {
                                section: title +' / ',
                                title: ['name']
                            }
                        }
                    },
                    {
                        wrappers: ['panel'], templateOptions: {label: 'Anagrafica'}, fieldGroupClassName: 'row',
                        fieldGroup: [
                            {key: 'name'         , type: 'input'   , className: 'col-12', templateOptions: {label: 'Nome'    , required: true}},
                            {key: 'inhabitants'  , type: 'input'   , className: 'col-4' , templateOptions: {label: 'Abitanti'                }},

                        ]
                    },
                    this.SUB_IDENTIFIERS(apipath),
                ];
                break;

            case 'memberships':
                title = 'Incarichi';

                fields = [
                    {type: 'header', templateOptions: {
                            headerOptions: {
                                section: title +' / ',
                                title: ['label']
                            }
                        }
                    },
                    {...this.SUBFORM_PERSON_SINGLE_MEMBERSHIP(), ...{ wrappers: ['panel'], templateOptions: {label: 'Incarico'} } },
                    this.SUB_CONTACT_DETAILS(),
                    this.SUB_APPOINTEES(),
                    this.SUB_CLASSIFICATIONS(apipath, id, alive),
                    this.SUB_SOURCES(),
                    this.SUB_LINKS(),
                ];

                options = {
                     formState: {
                       allowDelete: true,
                       supportsCopy: true,
                       copyFields: ['organization','post_role','person','label','area','start_date','end_date','end_reason','electoral_list_descr_tmp','constituency_descr_tmp','electoral_event','appointed_by']
                     }
                };
                break;
        }
        // @formatter:on
        /* tslint:enable */

        if (this.debug) {
          this.fieldsAddCyKeyAttribute(fields);
        }
        return {title, fields, options};
    }
}
