// ANGULAR MODULES
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {EventEmitter, Injectable, OnDestroy} from '@angular/core';

// RXJS
import {Observable} from 'rxjs';
import {map, takeWhile, share} from 'rxjs/operators';

// VENDOR
import {ToastrService} from 'ngx-toastr';

// CONFIG
import {AppConfig} from '../app.config';

@Injectable()
export class APICommonService implements OnDestroy {

    private alive = true;
    public actionsBus: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private config: AppConfig
    ) { }

    public ngOnDestroy() {
        this.alive = false;
    }

    public prefetchByParams(apipath: string, params: any): Observable<any> {
      params = this.preProcessParams(apipath, params);
      return this.http.get<any>(this.config.API.baseURL + apipath, this.jwt(params)).pipe(
        map(response => { return this.preProcessData(apipath, response) } ),
        share()
      );
    }

    public getByParams(apipath: string, params: any): Observable<any> {
        params = this.preProcessParams(apipath, params);
        return this.http.get<any>(this.config.API.baseURL + apipath, this.jwt(params)).pipe(
            map(response => { return this.preProcessData(apipath, response) } )
        );
    }

    public getById(apipath: string, id: number | string): Observable<any> {
        return this.http.get(this.config.API.baseURL + apipath + '/' + id, this.jwt()).pipe(
            map(response => { return this.preProcessData(apipath, response, id) } )
        );
    }

    public create(apipath: string, data: any): Observable<any> {
        return this.http.post(this.config.API.baseURL + apipath, data, this.jwt());
    }

    public update(apipath: string, data: any, id?: number | string): Observable<any> {
        const updateID = id || data.id;
        return this.http.put(this.config.API.baseURL + apipath + '/' + updateID, data, this.jwt());
    }

    public patch(apipath: string, data: any, id?: number | string): Observable<any> {
        const patchID = id ? '/' + id : '';
        return this.http.patch(this.config.API.baseURL + apipath + patchID, data, this.jwt());
    }

    public delete(apipath: string, id: number | string): Observable<any> {
        return this.http.delete(this.config.API.baseURL + apipath + '/' + id, this.jwt());
    }

    private getApiPathCase(apipath: string, id: number | string = null): string {

        apipath = apipath.split('?')[0]; // cleanup apipath from eventual query parameters
        return id ? apipath + '/$ID' : apipath;
    }

    private preProcessParams(apipath: string, params: any) {

        const apipath_case = this.getApiPathCase(apipath);

        switch (apipath_case) {

            case 'persons':
                if (params.gender && params.gender === 'gender_not_specified') {
                    delete params.gender;
                    params.gender_not_specified = 1;
                }
                break;
        }
        return params;
    }

    private preProcessData(apipath: string, response: any, id: number | string = null): any {

        const apipath_case = this.getApiPathCase(apipath, id);

        switch (apipath_case) {

            case 'organizations/$ID':
                if (Array.isArray(response.key_events)) {
                    response.key_events = response.key_events.map(item => {
                        return {...item.key_event};
                    });
                }

                if (Array.isArray(response.classifications)) {
                    response.classifications = response.classifications.map(item => {
                        return {...item.classification};
                    });
                }
                break;
            case 'persons':
                if (Array.isArray(response.results)) {
                    response.results.forEach( item => {
                        item.total_label = item.name + ' - ' + item.birth_location + ' ' + item.birth_date;

                        if (item.birth_location_area && !item.birth_location) {
                            item.birth_location = item.birth_location_area.name;
                        }
                    });
                }
                break;
            case 'persons/$ID':
                if (Array.isArray(response.classifications)) {
                  response.classifications = response.classifications.map(item => {
                    return {...item.classification};
                  });
                }
                if (response.birth_location_area && !response.birth_location) {
                    response.birth_location = response.birth_location_area.name;
                }
                response.total_label = response.name + ' - ' + response.birth_location + ' ' + response.birth_date;
                break;
            case 'persons/role_types':
                // response.results = response.results.sort( (a, b) => a.priority - b.priority).map( role => role.label );
                response.results = response.results.sort( (a, b) => a.priority - b.priority).map( role => { role.id = role.label; return role; } );
                // console.log(response.results);
                break;
            case 'memberships':
                if (Array.isArray(response.results)) {
                    response.results.forEach( O => {
                        O.person.total_label = O.person.name + ' - ' + O.person.birth_location + ' ' + O.person.birth_date;
                        O.appointer = O.person.name + ' - ' + O.post.label + ' - ' + O.start_date + ' / ' + O.end_date;
                        O.post_role = O.post.role;
                    });
                }
                break;
            case 'memberships/$ID':
                if (response.person) {
                    const o = response.person;
                    o.total_label = o.name + ' - ' + o.birth_location + ' ' + o.birth_date;
                }
                if (response.organization) {
                    const o = response.organization;
                    response.forma_giuridica = o.forma_giuridica;
                    o.label = o.name;
                }
                if (response.post) {
                    const o = response.post;
                    response.post_role = o.role;
                }
                if (response.appointed_by) {
                    // tslint:disable-next-line:max-line-length
                    response.appointed_by.appointer = response.appointed_by.person_name + ' - ' + response.appointed_by.label + ' - ' + response.appointed_by.start_date + ' / ' + response.appointed_by.end_date
                }
                if (Array.isArray(response.classifications)) {
                    response.classifications = response.classifications.map(item => {
                        return {...item.classification};
                    });
                }
                if (response.person && response.post) {
                  response.appointer = response.person.name + ' - ' + response.post.label + ' - ' + response.start_date + ' / ' + response.end_date;
                }

                // console.log('aio >>>>>>>', {...response});
                break;
            case 'areas':
              if (Array.isArray(response.results)) {
                response.results.forEach( O => {
                  O.name = O.name + ' (' + O.istat_classification + ')';
                })
              }
            break
            case 'persons/akas':
                if (Array.isArray(response.results)) {
                    response.results.forEach( O => {
                      if (O.loader_context === {}) {
                        console.log('empty loader_context', O);
                        // empty loader_context
                        O.loader_context.item = {
                          gender: '',
                          sources: [],
                          birth_date: '',
                          given_name: 'no loader_context.item',
                          family_name: 'no loader_context.item',
                          identifiers: [],
                          birth_location: 'no loader_context.item'
                        }
                      }
                        // CRITICAL DB IS CORRUPT!

                        if (O.loader_context &&
                            O.loader_context.item &&
                            !Array.isArray(O.loader_context.item.memberships)
                        ) {
                            O.loader_context.item.memberships = [
                                {
                                    label: O.loader_context.item.post_label,
                                    role: O.loader_context.item.role_type_label,
                                    start_date: O.loader_context.item.start_date,
                                    end_date: O.loader_context.item.end_date,
                                }
                            ];
                        }
                    });
                }
                break;
/*            case 'organizations/classifications':
                if (Array.isArray(response.results)) {
                    response.results.forEach( item => {item.descr += ' (' + item.scheme + ')'});
                }
                break;*/
        }
        // console.log('preProcessData ', apipath_case, response.results[0]);
        return response;
    }

    public similarityCheck(apipath: string, queryParams: any) {

        this.actionsBus.emit({
            type: 'similarity_load',
            data: true
        });

        this.getByParams(apipath + '/search', queryParams)
            .pipe(takeWhile(() => this.alive))
            .subscribe(
                response => {
                    this.actionsBus.emit({
                            type: 'similarity_check',
                            apipath: apipath,
                            queryParams: queryParams,
                            data: response
                    });
                    this.actionsBus.emit({
                        type: 'similarity_load',
                        data: false
                    });
                },
                err => {
                    this.toastr.error(err.message, 'Errore ' + err.status, {timeOut: 10000});
                    console.log('Error occured', err);
                });
    }

    //
    // JWT helper method
    //
    private jwt(params?: any) {

        // create authorization header with jwt token
        const currentUser = JSON.parse(localStorage.getItem('OPDM_currentUser'));
        if (currentUser && currentUser.token) {
            const httpOptions = {
                headers: new HttpHeaders({
                    'Authorization': 'Bearer ' + currentUser.token
                })
            };

            if (params) {
                // delete null, undefined & empty string filters from param object (unsupported by the API)
                Object.keys(params).forEach((key) => ( !params[key] && delete params[key] ) );

                httpOptions['params'] = params;
            }

            return httpOptions;
        }
    }
}
